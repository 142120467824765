<template>
  <div class="attachment-upload" v-bind="$attrs">
    <div class="head d-flex">
      <div class="title">
        <div class="title-text" v-if="title !== undefined">
          {{ title }}
        </div>
        <slot name="title"></slot>
      </div>
      <div class="upload" @click="upload" v-if="!disabled">
        <a-icon type="cloud-upload"></a-icon>
        <div>上传文件</div>
      </div>
    </div>
    <div class="content">
      <template v-if="fileList.length > 0">
        <div class="item d-flex a-i-center" v-for="(item, index) in fileList" :key="index">
          <div class="left d-flex a-i-center">
            <div class="file-icon">
              <img v-if="checkFileType('img',item.file_type)" :src="$toUrl('image.png')">
              <img v-else-if="checkFileType('excel',item.file_type)" :src="$toUrl('xls.png')">
              <img v-else-if="checkFileType('word',item.file_type)" :src="$toUrl('word.png')">
              <img v-else-if="checkFileType('pdf',item.file_type)" :src="$toUrl('pdf.png')">
              <img v-else :src="$toUrl('qitafujian.png')">
            </div>
            <div class="file-info">
              <div class="file-info-name">{{ item.file_name }}{{ item.file_type }}</div>
              <div class="d-flex a-i-center">
                <div class="file-info-size">{{ renderSize(item.file_length - 0) | numberFormat }}KB</div>
                <!--              <div class="file-info-from">来自3083310143@qq.com</div>-->
              </div>
            </div>
          </div>
          <div class="right d-flex a-i-center">
            <div v-if="!disabled" class="file-operation" @click="upload(item)">
              <a-icon type="reload"></a-icon>
            </div>
            <div v-if="!disabled" class="file-operation" @click="openHistory(item)">
              <a-icon type="history" />
            </div>
            <div class="file-operation" @click="$preview(item)">
              <a-icon type="file-search"></a-icon>
            </div>
            <a :href="item.file_path+'?token='+token" target="_blank" class="file-operation">
              <a-icon type="cloud-download"></a-icon>
            </a>
            <div v-if="!disabled" class="file-operation" @click="removeFile(item)">
              <a-icon type="close"></a-icon>
            </div>
          </div>
        </div>
      </template>
      <edp-empty v-else />
    </div>
    <input v-show="false" type="file" ref="upload" @change="handleFileChange">
    <upload-modal
        :source-oid="sourceOid"
        :source-type="sourceType"
        :prj-oid="prjOid"
        ref="uploadModal"
        @reload="() => {
        getAttachmentList()
        $emit('change')
      }">

    </upload-modal>
    <attachment-history-modal ref="attHistoryModal" @reload="() => {
      getAttachmentList()
      $emit('change')
    }"></attachment-history-modal>
  </div>
</template>

<script>
import { fetch } from "@/utils/request";
import { renderSize, checkFileType } from "@/utils/helpFunc";
import uploadModal from "@/components/uploadModal.vue";
import attachmentHistoryModal from "@/components/attachmentHistoryModal.vue";

export default {
  name: "attachmentUpload",
  emits: ['setOid', 'change'],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    sourceOid: { // source_oid来源oid 必填
      type: String
    },
    sourceType: { // source-type来源类型 必填
      type: String,
      default: ''
    },
    prjOid: { // 项目oid 选填
      type: String
    }
  },
  data() {
    return {
      fileList: [],
      renderSize,
      checkFileType,
      genOid: "",
      token: ""
    }
  },
  components: { attachmentHistoryModal, uploadModal },
  watch: {
    sourceOid: {
      deep: true,
      immediate: true,
      handler(sourceOid) {
        if (sourceOid === this.genOid && sourceOid !== '') {
          return
        }
        if (this.sourceOid) {
          this.getAttachmentList()
        } else {
          this.fileList = []
          this.genOid = this.$genGuid()
          console.log('genOid', this.genOid)
          this.$emit("setOid", this.genOid)
        }
      }
    }
  },
  created() {
    this.token = window.localStorage.getItem("token")
  },
  mounted() {

  },
  methods: {
    upload(item) {
      console.log(item);
      this.$refs.uploadModal.open(item)
    },
    handleFileChange(e) {
      if (e.target.files && e.target.files[0]) {
        let formData = new FormData()
        const file = e.target.files[0]
        formData.append('source_oid', this.sourceOid)
        if (this.prjOid) formData.append('prjoid', this.prjOid)
        formData.append('sec_level', 0)
        formData.append('file', file)
        fetch('post', '/attachment/upload', formData).then((res) => {
          if (res.status === 0) {
            let data = res.data
            this.fileList.unshift(data)
            // console.log(data);
            e.target.value = ''

            this.$emit("change")
            this.$message.success('上传成功！')
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    getAttachmentList() {
      fetch('post', '/attachment/retrieve', {
        source_oid: this.sourceOid
      }).then((res) => {
        if (res.status === 0) {
          let data = res.data.datalist
          // console.log(data);
          this.fileList = data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    removeFile(item) {
      console.log(item);
      this.$confirm({
        title: '确定要删除吗?',
        onOk: () => {
          return fetch('post', '/attachment/delete', {
            oid: item.oid
          }).then((res) => {
            if (res.status === 0) {
              this.$message.success('删除成功！')
              this.$emit("change")
              this.getAttachmentList()
            } else {
              this.$message.error(res.message)
            }
          })
        },
        onCancel() {
        },
      });
    },
    openHistory(row) {
      this.$refs.attHistoryModal.open(row)
    }
  }
}
</script>

<style scoped lang="less">
.attachment-upload {
  .head {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .title {
      font-weight: 500;
      font-size: 16px;
      color: #303030;
    }

    .upload {
      cursor: pointer;
      font-size: 14px;
      color: #494949;
      display: flex;
      line-height: 16px;
      height: 16px;
      align-items: center;

      > i {
        font-size: 16px;
        margin-right: 6px;
      }
    }
  }

  .content {
    .item {
      margin-bottom: 15px;
      border-bottom: 1px solid #C2C9CF;
      padding-bottom: 15px;
      justify-content: space-between;
      overflow: hidden;

      .left {
        width: calc(100% - 120px);

        .file-icon {
          width: 32px;
          height: 32px;
          margin-right: 5px;

          > img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }

        .file-info {
          font-weight: 500;
          font-size: 12px;
          color: #9B9B9B;
          width: calc(100% - 37px);

          .file-info-name {
            text-overflow: ellipsis;
            color: #303030;
            margin-bottom: 3px;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
          }

          .file-info-size {
            margin-right: 25px;
          }
        }
      }

      .right {
        flex-shrink: 0;

        > .file-operation {
          display: block;
          cursor: pointer;
          margin-left: 11px;

          > i {
            font-size: 14px;
            color: #303030;
            font-weight: 700;
          }
        }

        //>.file-operation:nth-of-type(1){
        //  margin-left: 0;
        //}
      }
    }

    & > .item:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
}
</style>

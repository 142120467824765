<template>
  <a-modal v-model="visible" title="历史附件" width="680px" class="uploadModal">
    <a-table :data-source="list" :bordered="true" row-key="content" :pagination="false" rowKey="oid">
        <a-table-column title="文件名称" data-index="file_name">
          <template #default="file_name, row">
            <div>
              <span v-if="pPreview" class="blue" @click="$preview(row, true)">{{file_name}}</span>
              <span v-else>{{file_name}}</span>
            </div>
          </template>
        </a-table-column>
        <a-table-column title="上传人" data-index="uploader_name" width="130px">
          <template #default="uploader_name">
            <div>
              {{uploader_name}}
            </div>
          </template>
        </a-table-column>
        <a-table-column title="上传时间" data-index="update_time" width="120px">
          <template #default="update_time">
            <div>
              {{update_time}}
            </div>
          </template>
        </a-table-column>
        <a-table-column title="操作" width="70px">
          <template #default="row">
            <div class="blue" @click="rollback(row)" v-if="pRollback">采用</div>
          </template>
        </a-table-column>
      </a-table>
    <template #footer>
      <div class="modal-footer">
        <a-space>
          <a-button @click="visible = false">
            关闭
          </a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
</template>

<script>
import {fetch} from "@/utils/request";

export default {
  name: "attachmentHistoryModal",
  props: {
    pRollback: {
      type: Boolean,
      default: true
    },
    pPreview: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return{
      visible: false,
      list: []
    }
  },
  methods: {
    open(row){
      console.log(row);
      this.getHistroyList(row.oid)
      this.visible = true
    },
    getHistroyList(oid) {
      fetch('post', '/attachment/history/retrieve', {
        oid
      }).then((res) => {
        if (res.status === 0) {
          this.list = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    rollback(row) {
      console.log(row);
      fetch('post', '/attachment/rollback', {
        oid: row.oid,
        file_oid: row.file_oid
      }).then((res) => {
        if (res.status === 0) {
          this.$message.success('采用成功！')
          this.$emit('reload')
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.blue {
  color: var(--primary-color);
  cursor: pointer;
}
</style>
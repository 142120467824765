<template>
  <div :id="id" :ref="id" v-show="false"></div>
</template>
<script>
const appsettings = require('../utils/appsettings')
// 支持分片上传文件
export default {
  name: 'el-chunkuploader',
  props: ['accept'],
  data: function () {
    return {
      appsettings: appsettings,
      id: 'webuploader_' + (new Date().getTime()) + Math.floor(Math.random() * (999)),
      uploadingFiles: [], // 正在上传的文件列表
      uploader: {
        options: {
          server: '',
          multiple: false
        }
      },
      progresschange_callback: null, // 上传进度回调
      success_callback: null, // 上传成功回调
      successFiles: [], // 上传成功的文件列表
      formData: {file_name: '', des: '', sec_level: ''},
      token: null
    }
  },
  components: {},
  mounted: function () {
    // eslint-disable-next-line
    /*eslint no-extend-native: ["error", { "exceptions": ["Array"] }]*/
    Array.prototype.removeByKeyAndValue = function (key, val) {
      for (var i = 0; i < this.length; i++) {
        if (this[i][key] === val) {
          this.splice(i, 1)
        }
      }
    }
    var self = this
    self.__InitUploader()
    this.token = localStorage.getItem('token') || 'cssc'
  },
  created: function () {

  },
  watch: {
  },
  methods: {
    // 移除一个文件
    remove: function (fid) {
      this.uploadingFiles.removeByKeyAndValue('fid', fid)
      this.successFiles.removeByKeyAndValue('fid', fid)
    },
    // 清除列表数据
    clear: function () {
      this.uploadingFiles = []
      this.successFiles = []
    },
    // 上传多个新文件
    // eslint-disable-next-line camelcase
    uploadNewFiles: function (form_data, progresschange_callback, success_callback, url = null) {
      if ((url || '').length > 0) {
        this.uploader.options.server = this.appsettings.apiroot + url
      }
      this.uploader.options.multiple = false
      // eslint-disable-next-line camelcase
      this.formData = form_data
      // eslint-disable-next-line camelcase
      this.success_callback = success_callback
      // eslint-disable-next-line camelcase
      this.progresschange_callback = progresschange_callback
      // $(document.getElementById(this.id).getElementsByClassName('webuploader-element-invisible')[0]).click()
      console.log(this.$refs[this.id].getElementsByClassName('webuploader-element-invisible')[0]);
      this.$refs[this.id].getElementsByClassName('webuploader-element-invisible')[0].click()
    },
    __InitUploader: function () {
      var self = this
      // eslint-disable-next-line no-undef
      this.uploader = WebUploader.create({
        // swf文件路径
        swf: this.appsettings.assetsroot + 'webuploader/Uploader.swf',
        // 文件接收服务端。
        server: this.appsettings.apiroot + 'attachment/upload',
        chunked: true,
        threads: 1,
        auto: false,
        formData: {
          guid: 'guid'
        },
        // 选择文件的按钮。可选。
        // 内部根据当前运行是创建，可能是input元素，也可能是flash.
        pick: {multiple: false, id: '#' + self.id},
        // 不压缩image, 默认如果是jpeg，文件上传前会压缩一把再上传！
        resize: false,
        chunkSize: 1024 * 1024 * 5,
        duplicate: true,
        accept: self.accept
      })

      this.uploader.on('fileQueued', function (file) {
        console.log('选择文件', file);
        file.index = self.uploadingFiles.length
        file.fid = 'fid_' + (new Date().getTime()) + Math.floor(Math.random() * (999))
        file.progress = 0
        file.file_type = file.ext
        file.file_size = file.size
        file.file_name = file.name
        file.formData = {
          ...self.formData,
          token: window.localStorage.getItem('token'),
          file_size: file.size,
          file_name: file.name,
          file_type: file.ext
        }
        console.log(file.formData);
        self.uploadingFiles.push(file)
        self.$emit('getFiles', file)
        console.log(self.uploadingFiles);
      })
      this.uploader.on('uploadProgress', function (file, percentage) {
        file.progress = (percentage * 100 + '').substring(0, 5)
        if (file.progress === '100') {
          file.progress = '99.99'
        }
        self.progresschange_callback(file)
      })

      // eslint-disable-next-line no-unused-vars
      this.uploader.on('uploadBeforeSend', function (obj, data, headers) {
        Object.keys(obj.file.formData).forEach(function (key) {
          data[key] = obj.file.formData[key]
        })
        console.log(obj, data);
      })

      this.uploader.on('uploadAccept', function (file, response) {
        // response = JSON.parse(response)
        if (response != null) {
          if (response.status === 0) {
            return true
          } else if (response.status === 9999) {
            self.$router.push({ path: '/login', query: { goindex: true } })
            return false
          }
        }
      })

      this.uploader.on('uploadSuccess', function (file, response) {
        // response = JSON.parse(response)
        if (response != null) {
          if (response.status === 0) {
            // 一个文件上传成功了
            response.data_ = response.data
            response.data = {}
            response.data.progress = '100'
            response.data.index = file.index
            response.data.fid = file.fid
            response.data.ok = true
            self.uploadingFiles.removeByKeyAndValue('fid', file.fid)
            self.success_callback(response.data, response)
          } else if (response.status === 9999) {
            self.$router.push({ path: '/login', query: { goindex: true } })
          } else {
            self.success_callback(response.data, response)
          }
        }
      })

      // eslint-disable-next-line no-unused-vars
      this.uploader.on('uploadError', function (file) { })
      // eslint-disable-next-line no-unused-vars
      this.uploader.on('uploadComplete', function (file) { })
    },
    refresh: function () {
      this.uploader.refresh()
    }
  }
}
</script>
<style></style>

<template>
  <a-modal v-model="visible" title="上传附件" width="680px" class="uploadModal" :maskClosable="false">
    <a-form-model layout="vertical" ref="form" :model="form" :rules="rules" class="edp-form">
      <a-form-model-item label="" prop="tempFile">
<!--        <a-upload :action="`${$appsettings.apiroot}/attachment/upload`" :show-upload-list="false" :before-upload="beforeUpload" :customRequest="customUploader">-->
        <div class="ant-upload">
          <div class="upload" @click="chunkUploader">
            <div class="upload-file" v-if="Object.keys(form.tempFile).length > 0">
              <div class="file-icon">
                <img v-if="checkFileType('img',form.tempFile.file_type)" :src="$toUrl('image.png')" alt="">
                <img v-else-if="checkFileType('excel',form.tempFile.file_type)" :src="$toUrl('xls.png')" alt="">
                <img v-else-if="checkFileType('word',form.tempFile.file_type)" :src="$toUrl('word.png')" alt="">
                <img v-else-if="checkFileType('pdf',form.tempFile.file_type)" :src="$toUrl('pdf.png')" alt="">
                <img v-else :src="$toUrl('qitafujian.png')">
              </div>
              <div class="file-info">
                <div class="file-name">{{form.tempFile.file_name}}</div>
                <div class="file-size">{{renderSize(form.tempFile.file_length - 0) | numberFormat }}KB</div>
              </div>
            </div>
            <a-icon v-else type="plus"></a-icon>
          </div>
        </div>
<!--        </a-upload>-->
        <a-progress v-if="form.percent > 0" :percent="form.percent" />
      </a-form-model-item>
      <div class="line-1">
        <a-form-model-item label="文件名称" prop="file_name">
          <a-input class="file-name" v-model="form.file_name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="级别" prop="sec_level">
          <a-select class="file-sec" v-model="form.sec_level">
            <a-select-option v-for="item in ['0', '1', '2', '3', '4', '5']" :key="item" :value="item">
              {{item}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </div>
      <a-form-model-item label="文件描述" prop="des">
        <a-textarea :rows="4" v-model="form.des"></a-textarea>
      </a-form-model-item>
    </a-form-model>
    <template #footer>
      <div class="modal-footer">
        <a-space>
          <a-button @click="cancel">
            取消
          </a-button>
          <a-button type="primary" :disabled="form.flag" @click="submit">
            确定
          </a-button>
        </a-space>
      </div>
    </template>
    <ElChunkuploader ref="ChunkUploader" @getFiles="customUploader"></ElChunkuploader>
  </a-modal>
</template>

<script>
import {fetch} from "@/utils/request";
import {checkFileType, renderSize} from "@/utils/helpFunc";
import ElChunkuploader from "@/components/el-ChunkUploader.vue";

export default {
  name: "uploadModal",
  components: {ElChunkuploader},
  props: {
    sourceOid: { // source-oid来源oid 必填
      type: String
    },
    sourceType: { // source-type来源类型 必填
      type: String,
      default: ''
    },
    prjOid: { // 项目oid 选填
      type: String
    },
    prjFolderOid: { // 所属文件夹 选填
      type: String
    },
    url: { // 选填 有的上传接口不同
      type: String,
      default: 'attachment/upload'
    }
  },
  data() {
    return {
      form: {
        file_name: '',
        sec_level: '0',
        file: '',
        des: '',
        tempFile: {},
        percent: 0,
        oid: '',
        flag: false
      },
      rules: {
        file_name:[{required: true, message: "请填写文件名称", trigger: 'change'}],
        tempFile:[{required: true, message: "请上传文件", trigger: 'change'}]
      },
      visible: false,
      backup: {},
      formData: new FormData(),
      checkFileType,
      renderSize
    }
  },
  watch: {
    sourceOid: {
      deep: true,
      immediate: true,
      handler () {
        if (this.sourceOid) {
          // console.log('prjOid', this.prjOid)
        }
      }
    }
  },
  created() {
    this.backup = JSON.parse(JSON.stringify(this.form))
  },
  methods: {
    open(item) {
      this.form = {
        ...this.backup
      }
     if (item?.oid) { // 更新
       console.log(item);
       this.form = {
         ...this.form,
         tempFile: {
           ...item
         },
         oid: item.oid,
         file_name: item.file_name,
         des: item.description,
         sec_level: item.sec_level
       }
     }
      this.visible = true
    },
    beforeUpload(file) {
      return file
    },
    customUploader(data) {
      // console.log(data);
      const file = data // webuploader上传
      let allowedTypes = this.storage.getItem('allowed_types')
      if (!allowedTypes.includes(`.${file.file_type}`)) {
        this.$message.info('您上传的文件类型暂不支持！')
        return false
      }

      this.form.tempFile = {
        file_name: file.name,
        file_length: file.size,
        file_type: file.file_type
      }
      let name = file.name.substring(0, file.name.lastIndexOf("."))
      if (!this.form.file_name) this.form.file_name = name

      // console.log(this.$refs.ChunkUploader.uploadingFiles[0].formData);
    },
    progress(percent) {
      // console.log(percent);
      this.form.percent = percent
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.form.flag = true
          // console.log(valid, this.form);
          // console.log(this.$refs.ChunkUploader.uploader);
          // console.log(this.$refs.ChunkUploader.uploader.getFiles().length);
          if (this.form.oid && this.$refs.ChunkUploader.uploader.getFiles().length === 0){
            console.log('没有更新文件修改名称')
            this.formData = new FormData()
            this.formData.append('file_name', this.form.file_name)
            this.formData.append('des', this.form.des)
            this.formData.append('sec_level', this.form.sec_level)
            this.formData.append('oid', this.form.oid)
            fetch('post', this.url, this.formData).then((res) => {
              if (res.status === 0) {
                console.log(res.data);
                this.visible = false
                this.$emit('reload')
                this.$message.success('更新成功！')
              } else {
                this.visible = false
                this.$message.error(res.message)
              }
            })
            return false
          }
          console.log('新增或者更新文件')
          console.log(this.$refs.ChunkUploader.uploadingFiles[0])
          this.$refs.ChunkUploader.uploadingFiles[0].formData.file_name = this.form.file_name
          this.$refs.ChunkUploader.uploadingFiles[0].formData.sec_level = this.form.sec_level || '0'
          this.$refs.ChunkUploader.uploadingFiles[0].formData.des = this.form.des
          this.$refs.ChunkUploader.uploader.upload()
        }
      });
    },
    getAttachment(oids) {
      fetch('post', '/attachment/retrieve', {
        oids
      }).then((res) => {
        if (res.status === 0) {
          let data = res.data.datalist
          console.log(data[0])
          this.form = {
            ...this.form,
            tempFile: {
              ...data[0]
            },
            file_name: data[0].file_name,
            des: data[0].description
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 分片上传
    chunkUploader(){
      // 更换文件时清空上一个文件
      this.$refs.ChunkUploader.uploader.reset()
      //选择本地Excel文件，并上传
      this.$refs.ChunkUploader.uploadNewFiles(
          {
            prj_folderoid: this.prjFolderOid || '',
            prjoid: this.prjOid || '',
            source_oid: this.sourceOid || '',
            sec_level: this.form.sec_level,
            file_name: this.form.file_name,
            des: this.form.des,
            source_type: this.sourceType,
            oid: this.form.oid,

          },
          this.chunkUploading,
          this.chunkUploaded,
          this.url
      )
    },
    chunkUploading (data) {
      this.progress(parseInt(data.progress))
    },
    chunkUploaded (f, res) {
      if (res != null && res.status === 0) {
        // console.log(f, res);
        // 上传成功后清空上传列表
        this.$refs.ChunkUploader.uploader.reset()
        this.visible = false
        this.$emit('reload')
        this.$message.success('上传成功！')
      } else {
        this.visible = false
        this.$message.error(res.message)
      }
    },
    cancel() {
      // 取消停止上传
      this.$refs.ChunkUploader.uploader.reset()
      this.visible = false
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .ant-upload {
  width: 100%;

  .upload{
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #d9d9d9;
    cursor: pointer;

    .anticon-plus{
      font-size: 28px;
      color: #999;
      display: block;
    }

    .upload-file{
      width: 100%;
      height: 100%;
      padding-left: 10px;
      display: flex;
      align-items: center;

      .file-icon{
        width: 37px;
        height: 37px;
        margin-right: 13px;

        img{
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      .file-info{
        font-size: 16px;
        line-height: 16px;
        color: #4D4D4D;

        .file-size{

          margin-top: 11px;
          font-size: 14px;
          line-height: 14px;
          color: #A7A7A7;
        }
      }
    }
  }
}
/deep/ .ant-progress{
  padding-top: 5px;
}
/deep/ .ant-form-vertical{
  .ant-form-item{
    padding-bottom: 0;
  }
}
/deep/ .line-1{
  display: flex;

  .file-name{
    width: 567px;
    border-right: none;
    border-radius: 4px 0 0 4px;
  }

  .file-sec{
    width: 65px;
    .ant-select-selection{
      border-radius: 0 4px 4px 0;
    }
  }
}
</style>
